import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <Container>
      <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item">
            <a href="/" className="nav-link px-2 text-muted" a="">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="#about" className="nav-link px-2 text-muted" a="">
              About
            </a>
          </li>
          <li className="nav-item">
            <a href="#conditions" className="nav-link px-2 text-muted" a="">
              Conditions
            </a>
          </li>
          <li className="nav-item">
            <a href="#treatments" className="nav-link px-2 text-muted" a="">
              Treatments
            </a>
          </li>
        </ul>
        <p className="text-center text-muted">© 2024 Inna Gordin Medical, PC</p>
      </footer>
    </Container>
  );
};

export default Footer;
