import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics"; // Import logEvent from firebase/analytics

function Navigation() {
  const { pathname, hash } = useLocation();
  const stringLocationPath = pathname + hash;
  

// Define a constant to control the visibility of the "For Patients" NavDropdown item
const SHOW_FOR_PATIENTS_DROPDOWN = false;

  //Analytics event for contact click
  const handleContactClick = () => {
    if (analytics) {
      logEvent(analytics, 'contact_clicked');
    } else {
      console.error('Analytics is not initialized');
    }
  };

  return (
    <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">Dr. Inna Gordin</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={stringLocationPath} className="me-auto">
            <Nav.Item>
              <Nav.Link as={Link} eventKey="/" to="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} eventKey="/#about" to="/#about">
                About
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} eventKey="/#conditions" to="/#conditions">
                Conditions
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} eventKey="/#treatments" to="/#treatments">
                Treatments
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} onClick={handleContactClick} eventKey="/#contact" to="/#contact">
                Contact
              </Nav.Link>
            </Nav.Item>
            {SHOW_FOR_PATIENTS_DROPDOWN && (
            <NavDropdown title="For Patients" id="basic-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                eventKey="/preparation"
                to="/preparation"
              >
                Preparation instructions
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} eventKey="/insurance" to="/insurance">
                Insurance information
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} eventKey="/faq" to="/faq">
                FAQ
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Patient Portal
              </NavDropdown.Item>
            </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
